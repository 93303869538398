.my-markdown p {
    color: #BCBCBC;
    padding-top: 0px;
    margin-top: 0px;
    padding-left: 0px;
    font-size: unset;
    font-weight: unset;
}

.highlight {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 120px;
    overflow: hidden;
}

@media (min-width: 901px) {
    .highlight {
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
    }      
}

.highlight img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media (min-width: 901px) {
    .highlight img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.highlight > span {
    flex: 1;
}

.secondary-img {
    display: none;
}

@media (min-width: 901px) {
    .secondary-img {
        display: block;
    }       
}
