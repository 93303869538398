.daterangepicker-control-section {
    max-width: 246px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    border: 1px solid #fa872d;
    border-radius: 15px;
    padding-left: 1em;
    padding-right: 1em;
    
  }


  
  .e-input-group .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    font-size: 18px;
    color: white;
    
}

.e-input-group.e-control-wrapper.customClass { 
    font-size: 18px; 
    color: whitesmoke; 
    

} 




